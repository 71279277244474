<template>
  <div>
    <PageHeader title="Dashboard" :full="true">
      <template #left>
        <Btn rounded link class="text-md hidden md:inline-flex" :action="getMetrics">
          <icon icon="arrows-rotate" v-tooltip="'Reload table...'" />
        </Btn>
      </template>
      <!-- Right header buttons -->
      <template #right>
        <div class="hidden md:flex flex-row items-center gap-4">
          <Choose
            v-show="companyChoices.length > 1"
            ref="companyChooseLarge"
            size="md"
            @close="onCompanyIdClose"
            v-model="chosenCompanyIds"
            :multiple="true"
            :returnArray="true"
            :forceNotEmpty="true"
            :allowCreate="false"
            :staticSet="companyChoices"
          >
            <Btn size="lg" severity="tertiary">
              <icon icon="building" />
            </Btn>
          </Choose>
          <MetricsPicker
            title="Time"
            :set="timeRanges"
            icon="calendar"
            @input="onTimeRangeSelected"
          />
          <btn severity="primary-black" size="lg" :action="() => chartPickerModal.open()">
            <icon icon="objects-column" class="text-xl" />
            <span class="hidden md:inline"> Charts </span>
          </btn>
        </div>
        <div class="md:hidden">
          <Btn rounded link :action="getMetrics">
            <icon icon="arrows-rotate" v-tooltip="'Reload table...'" class="text-2xl" />
          </Btn>
        </div>
      </template>
    </PageHeader>

    <!-- Mobile header buttons -->
    <div class="flex md:hidden flex-col gap-3 mb-8 mt-6">
      <btn severity="primary-black" size="lg" :action="() => chartPickerModal.open()">
        <icon icon="objects-column" class="text-xl" />
        Charts
      </btn>
      <Choose
        v-show="companyChoices.length > 1"
        ref="companyChooseSmall"
        size="md"
        @close="onCompanyIdClose"
        v-model="chosenCompanyIds"
        :multiple="true"
        :returnArray="true"
        :forceNotEmpty="true"
        :staticSet="companyChoices"
        :allowCreate="false"
        btnClass="!w-full border border-surface-700 rounded"
      />
      <MetricsPicker title="Time" :set="timeRanges" icon="calendar" @input="onTimeRangeSelected" />
    </div>

    <!-- Loading spinner -->
    <div v-if="!loaded" class="flex justify-center items-center w-full h-full mt-[30vh]">
      <spinner :loading="!loaded" size="4em" />
    </div>

    <!-- Charts and cards -->
    <div v-if="loaded" class="flex flex-col animate-fadeIn">
      <!-- Chart and card draggable grid -->
      <draggable
        class="flex flex-col md:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8 overflow-y-hidden"
        :list="metricCharts"
        :group="{ name: 'group', pull: false, put: false }"
        :sort="true"
        :disabled="smallFormat"
        @end="setChartOrdering"
      >
        <template #item="{ element }">
          <MetricsChart :object="element" v-show="element.visible" :id="element.title" />
        </template>
      </draggable>
      <!-- No charts selected hint -->
      <div
        v-if="!visibleCharts.length"
        class="flex flex-col w-full justify-center items-center mt-12 gap-y-6 *:text-surface-400"
      >
        <icon icon="chart-simple" class="text-4xl text-surface-400" />
        <p class="text-2xl">No charts selected.</p>
        <btn
          severity="tertiary"
          class="text-surface-400 border-surface-300"
          :action="() => chartPickerModal.open()"
        >
          Select charts
        </btn>
      </div>
    </div>

    <!-- Chart selector modal -->
    <Modal ref="chartPickerModal" :scrollable="true">
      <template #header>
        <icon icon="objects-column" class="text-2xl" />
        <span class="text-2xl">Visible charts</span>
      </template>
      <template #body>
        <MetricsChartPicker />
      </template>
    </Modal>
  </div>
</template>

<script setup>
import PageHeader from '@/components/layout/page/PageHeader.vue'
import MetricsPicker from '@/components/metric/MetricsPicker.vue'
import MetricsChartPicker from '@/components/metric/MetricsChartPicker.vue'
import MetricsChart from '@/components/metric/charts/MetricsChart.vue'
import draggable from 'vuedraggable'
import { useMetrics } from '@/components/metric/Metrics'
import { useStore } from 'vuex'
import { ref, onBeforeMount, onMounted, computed } from 'vue'
import { useMediaQuery } from '@/composables/mediaQuery'

const store = useStore()

const { smallFormat } = useMediaQuery()

const {
  getMetrics,
  setTimeRange,
  timeRanges,
  setCompanyIds,
  resetCompanyIds,
  metricCharts,
  loaded,
  setChartOrdering
} = useMetrics()

// Template refs
const companyChooseLarge = ref()
const companyChooseSmall = ref()
const chartPickerModal = ref()

/**
 * Visible metric charts.
 */
const visibleCharts = computed(() => {
  return metricCharts.value.filter((chart) => chart.visible)
})

/**
 * Company choices.
 * The options that appear in the top choose for company selection.
 */
const companyChoices = computed(() => {
  // All franchisees
  let choices = []
  store.state.session.company.aoFranchisees.forEach((franchisee) => {
    choices = [
      ...choices,
      {
        text: franchisee.franchisee_name,
        value: franchisee.franchisee_id
      }
    ]
  })
  return choices
})
const chosenCompanyIds = ref(
  companyChoices.value.length
    ? companyChoices.value.map((choice) => choice.value)
    : [store.state.session.company.company_id]
)
const lastChosenCompanyIds = ref(chosenCompanyIds.value)

/**
 * On company ID close.
 * Called when the company choose is closed.
 */
function onCompanyIdClose() {
  let newCompanies = null

  // Large format choose DOM element
  if (companyChooseLarge.value) {
    newCompanies = companyChooseLarge.value.rawValue.sort().reverse()
  }
  // Small format choose DOM element
  else if (companyChooseSmall.value) {
    newCompanies = companyChooseSmall.value.rawValue.sort().reverse()
  }
  // Only call setCompanyIds when newCompanies is valid and different from the current selection
  if (newCompanies && newCompanies.join(',') !== lastChosenCompanyIds.value.join(',')) {
    setCompanyIds(newCompanies)
    lastChosenCompanyIds.value = newCompanies
    getMetrics()
  }
}

/**
 * On time range selected.
 * Called when a selection is made from the time picker.
 */
function onTimeRangeSelected(range) {
  setTimeRange(range)
  getMetrics()
}

/**
 * Before mount.
 * Reset company ids to default ([]) before the company choose is mounted.
 * This is to ensure the default loaded data is this company's, not a
 * previously selected one.
 */
onBeforeMount(() => {
  resetCompanyIds()
})

/**
 * On mount.
 * Initialize time range then call getMetrics.
 */
onMounted(() => {
  setTimeRange(timeRanges.value[1].data)
  setCompanyIds(chosenCompanyIds.value)
  getMetrics()
})
</script>
